import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Title, Section, Box } from '../../components/Core';
import { device } from '../../utils';
import {
    BayutLogo,
    DubizzleLogo,
    ZameenLogo,
    OlxLogo,
    // KaideeLogo,
    // MubawabLogo,
    // BpropertyLogo,
    // SectorLabsLogo,
    // ZameenDevLogo,
} from '../../assets/image/png';
const SectionStyled = styled(Section)`
    border-bottom: 1px solid #2d2d30;
`;

const ContentWidget = styled(Box)`
    border-radius: 10px;
    border: 1px solid #2d2d30;
    background-color: ${({ theme }) => theme.colors.dark};
    min-width: 100%;
    width: 100%;
    min-height: 90px;
    display: flex;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    img {
        filter: brightness(0) invert(1);
        max-height: 48px;
    }

    @media ${device.xl} {
        width: 100%;
        min-width: 255px;
    }
    @media ${device.md} {
        min-height: 89px;
    }

    i {
        font-size: 30px;
        color: #fff;
        position: absolute;
        right: 0;
        margin-right: 30px;
        opacity: 0;
        transition: 0.4s;
    }

    &:hover {
        box-shadow: 0 52px 54px rgba(25, 25, 27, 0.3);
        border-radius: 10px;
        background-color: ${({ theme }) => theme.colors.light};
        img {
            filter: brightness(1) invert(0);
            transition: 0.4s;
        }
    }
    &:hover i {
        transform: translateX(10px);
        opacity: 1;
    }
`;

const ContentCard = ({ children = '', url }) => {
    return (
        <ContentWidget>
            <a
                className={`d-flex align-items-center w-100 px-4 py-4`}
                href={url}
                target="_blank"
                rel="noreferrer"
            >
                <Title
                    className="d-flex justify-content-center w-100"
                    color="light"
                    variant="card"
                    mb={0}
                >
                    {children}
                </Title>
            </a>
            {/* 
      <i className="fas fa-chevron-circle-right"></i> */}
        </ContentWidget>
    );
};

const Content = () => {
    return (
        <>
            {/* <!-- Content section --> */}
            <SectionStyled bg="dark">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="10">
                            <div className="text-center mb-5">
                                <Title color="light">Our Brands</Title>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col
                            lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="400"
                        >
                            <ContentCard url="https://www.dubizzle.com/">
                                <img src={DubizzleLogo} alt="Dubizzle" />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="600"
                        >
                            <ContentCard url="https://bayut.com/">
                                <img src={BayutLogo} alt="Bayut" />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                        >
                            <ContentCard url="https://www.zameen.com/">
                                <img src={ZameenLogo} alt="zameen" />
                            </ContentCard>
                        </Col>
                        <Col
                            lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="200"
                        >
                            <ContentCard url="https://www.olx.com.pk/">
                                <img src={OlxLogo} alt="olx" />
                            </ContentCard>
                        </Col>
                        {/* <Col
                            lg="3"
                            md="6"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1000"
                        >
                            <ContentCard url="http://zameendevelopments.com/">
                                <img src={ZameenDevLogo} alt="Zameen Dev" />
                            </ContentCard>
                        </Col> */}

                        {/* <Col
                            lg="3"
                            md="6"
                            className="mb-4"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            data-aos-delay="1000"
                        >
                            <ContentCard url="https://www.sectorlabs.ro/">
                                <img src={SectorLabsLogo} alt="Sector Labs" />
                            </ContentCard>
                        </Col> */}
                    </Row>
                </Container>
            </SectionStyled>
        </>
    );
};

export default Content;
